import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import * as chooseActions from '../ducks/chooseNumber';
import * as subscribeActions from '../ducks/subscribe';
import * as errorActions from '../ducks/error';
import * as verifyActions from '../ducks/verify';

import PhoneNumberContainer from '../containers/PhoneNumberAuthContainer';
import { ROUTERS, US } from '../constants';
import withRegistrationCheck from '../components/WithRegistrationCheck';
import * as amplitide from '@amplitude/analytics-browser';

import '!style-loader!css-loader!sass-loader!../../styles/phone-number.scss';

class PhoneNumberLoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: null,
      selectedCountry: {
        name: 'United States',
        dialCode: 1,
        countryCode: US,
      },
      isShowVerify: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleVerifySubmit = this.handleVerifySubmit.bind(this);
  }

  componentDidUpdate() {
    const { userVerified } = this.props;
    const { router } = this.context;

    if (userVerified) {
      router.push(ROUTERS.dashboard);
    }
  }

  handleVerifySubmit(number, verifyCode) {
    const { validateVerifyCode } = this.props;

    validateVerifyCode(number, verifyCode);
  }

  handleSubmit(number) {
    const { verifyErrorMessage, fetchVerifyCode } = this.props;
    const deviceId = amplitide.getDeviceId();

    if (!verifyErrorMessage) {
      fetchVerifyCode(number, false, deviceId);
    }
  }

  render() {
    return (
      <div id="login-page">
        <PhoneNumberContainer
          isShowBar={false}
          handleSubmitNumber={this.handleSubmit}
          handleSubmitVerify={this.handleVerifySubmit}
        />
      </div>
    );
  }
}

PhoneNumberLoginPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    error: state.error,
    codeWasSent: state.verifyPage.codeWasSent,
    verifyErrorMessage: state.verifyPage.verifyErrorMessage,
    isResend: state.verifyPage.isResend,
    userVerified: state.verifyPage.userVerified,
    selectedNumber: state.subscribePage.selectedNumber,
    platform: state.verifyPage.platform,
    user: state.auth.user,
  };
}

const mapDispatchToProps = {
  ...verifyActions,
  ...subscribeActions,
  ...errorActions,
  ...chooseActions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRegistrationCheck(PhoneNumberLoginPage));
