import React, { Component } from 'react';

import '!style-loader!css-loader!sass-loader!../../../styles/progressive-image.scss'

class ProgressiveBackgroundImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      src: props.lowQualitySrc,
      loading: true,
    };

    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;

    const { highQualitySrc } = this.props;
    const highQualityImage = new Image();
    highQualityImage.src = highQualitySrc;

    highQualityImage.onload = () => {
      if (this._isMounted) {
        this.setState({
          src: highQualitySrc,
          loading: false,
        });
      }
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { alt } = this.props;
    const { src, loading } = this.state;

    return (
      <picture>
        <source srcSet={src} type="image/webp"/>
        <img
          className="ProgressiveImage--image"
          srcSet={src}
          alt={alt}
          loading="eager"
          fetchpriority="high"
          style={{
            filter: loading ? 'blur(20px)' : 'none',
          }}
          width="1440"
          height="1024"
        />
      </picture>
    );
  }
}

export default React.memo(ProgressiveBackgroundImage);
