import React, { Component } from 'react';

import Container from '../components/common/Container';
import PaywallBanner from '../components/paywall/PaywallBanner';
import AppStoreRatings from '../components/paywall/AppStoreRatings';
import WhatYouGet from '../components/paywall/WhatYouGet';
import FeaturedIn from '../components/social-proof/FeaturedIn';
import Reviews from '../components/paywall/Reviews';
import * as amplitude from '@amplitude/analytics-browser';
import {AMPLITUDE_PAYWALL_VIEW, AUTHENTICATION_KEY, ROUTERS} from '../constants';
import { amplitudeDeviceProperty } from '../utils/analytics';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withRegistrationCheck from '../components/WithRegistrationCheck';
import {getCurrentUser} from '../utils/user';
import {setUserSignUpStep} from '../utils/sessionStorage';
import cookie from 'react-cookie';
import Loader from '../components/Loader';
import * as subscribeActions from '../ducks/subscribe';

class PaywallPage extends Component {
  async componentDidMount() {
    const { fetchSubscribeDetails } = this.props;
    const user = await getCurrentUser();
    const { router } = this.context;

    setUserSignUpStep(ROUTERS.paywall);
    fetchSubscribeDetails();

    const authToken = cookie.load(AUTHENTICATION_KEY);

    if (!authToken) {
      return router.push(ROUTERS.homePage);
    }

    if (user && (user.eligibleForPaywall || user.createdSample)) {
      amplitude.track(AMPLITUDE_PAYWALL_VIEW, amplitudeDeviceProperty);
    }
  }

  componentDidUpdate(prevProps) {
    const { clientSecretKey } = this.props;
    const { router } = this.context;

    if (clientSecretKey && prevProps.clientSecretKey !== clientSecretKey) {
      router.push(ROUTERS.checkout)
    }
  }

  render() {
    const { isFetching } = this.props;

    if (isFetching) {
      return <Loader/>;
    }

    return (
      <Container isShowBar isHideErrorModal classNameWrapper="u-height-full grid-y">
        <PaywallBanner />
        <Reviews />
        <AppStoreRatings />
        <WhatYouGet />
        <FeaturedIn className="Featured__container--paywall" />
      </Container>
    );
  }
}

PaywallPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    clientSecretKey: state.stripeSubscribe.clientSecretKey,
    user: state.auth.user,
    isFetching: state.subscribePage.isFetching,
  };
}
export default connect(mapStateToProps, subscribeActions)(withRegistrationCheck(PaywallPage));

