import { Box, Grid } from '@mui/material';
import React from 'react';

import { NYTimesIcon } from '../icons/featured/NewYorkTimes';
import { TimeIcon } from '../icons/featured/Time';
import { TnwIcon } from '../icons/featured/Tnw';
import { UrbanDaddyIcon } from '../icons/featured/UrbanDaddy';
import { LifehackerIcon } from '../icons/featured/Lifehacker';
import { TcIcon } from '../icons/featured/TC';
import { CnetIcon } from '../icons/featured/Cnet';
import { MashableIcon } from '../icons/featured/Mashable';

const icons = [
  { component: NYTimesIcon, name: 'NYTimesIcon' },
  { component: TimeIcon, name: 'TimeIcon' },
  { component: TnwIcon, name: 'TnwIcon' },
  { component: UrbanDaddyIcon, name: 'UrbanDaddyIcon' },
  { component: LifehackerIcon, name: 'LifehackerIcon' },
  { component: TcIcon, name: 'TcIcon' },
  { component: CnetIcon, name: 'CnetIcon' },
  { component: MashableIcon, name: 'MashableIcon' },
];

import '!style-loader!css-loader!sass-loader!../../../styles/featured-in.scss';
import classNames from 'classnames';

function FeaturedIn({className}) {
  return (
    <Box
      component="section"
      className={
        classNames(
          className,
          'Featured__container u-margin-x-auto u-padding-x-container',
        )
      }
    >
      <h2 className="Featured__title h4 medium">AS FEATURED IN</h2>
      <Grid className="Featured__column" container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {icons.map(({ component: Icon }, index) => (
          <Grid
            className="Featured__column--item"
            item xs={6} md={3} lg={3} key={index}
          >
            <Icon />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default React.memo(FeaturedIn);
