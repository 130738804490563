import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  AMPLITUDE_KEY,
  amplitudeProperties, BURNER_CURRENT_STEP, BURNER_PAGE_STATE, ENVIRONMENT,
  ROUTERS, SELECTED_NUMBER,
} from '../constants';
import '../../styles/vendor/typicons.css';
import '../../styles/vendor/animate.css';
import '!style-loader!css-loader!sass-loader!../../styles/vendor/foundation/foundation.scss';
import '!style-loader!css-loader!sass-loader!../../styles/_custom-webfont.scss';
import '!style-loader!css-loader!sass-loader!../../styles/utils-rebrand.scss';
import '!style-loader!css-loader!sass-loader!../../styles/application-rebrand.scss';
import '!style-loader!css-loader!sass-loader!../../styles/modal.scss';
import '!style-loader!css-loader!sass-loader!../../styles/modal-responsive.scss';
import '!style-loader!css-loader!sass-loader!../../styles/spinner.scss';
import '!style-loader!css-loader!sass-loader!../../styles/typography-rebrand.scss';
import '!style-loader!css-loader!sass-loader!../../styles/form-rebrand.scss';
import '!style-loader!css-loader!sass-loader!../../styles/buttons.scss';
import '!style-loader!css-loader!sass-loader!../../styles/header-rebrand.scss';
import '!style-loader!css-loader!sass-loader!../../styles/footer-rebrand.scss';
import '!style-loader!css-loader!sass-loader!../../styles/progress-bar.scss';

// load jquery and foundation in the window scope
import 'script-loader!what-input';
import 'core-js';
import { Foundation } from 'foundation-sites';
import jquery from 'jquery';
import * as amplitude from '@amplitude/analytics-browser';
import * as dashboardActions from '../ducks/dashboard'
import * as subscribeAction from '../ducks/subscribe'

import { getCurrentUser } from '../utils/user';
import { getStoragePlanData } from '../utils/sessionStorage';

window.jQuery = jquery;
window.$ = jquery;

Foundation.addToJquery($);

amplitude.init(AMPLITUDE_KEY, amplitudeProperties);

const propTypes = {
  children: PropTypes.node.isRequired, // Injected by React Router
  redirectBackAction: PropTypes.func,
};

const defaultProps = {
  redirectBackAction: () => {},
};

class App extends Component {
  componentWillMount() {
    dataLayer.push({ env: ENVIRONMENT });
  }

  componentDidMount() {
    const { router } = this.context;

    const userSignupStep = localStorage.getItem(BURNER_CURRENT_STEP);
    const storageNumber = localStorage.getItem(SELECTED_NUMBER);
    const storageUser = getCurrentUser();
    const sku = getStoragePlanData('sku');

    if (userSignupStep && (storageUser && storageUser.eligibleForPaywall) && storageNumber) {
      if (!sku && userSignupStep === ROUTERS.checkout) {
        router.push(ROUTERS.paywall);
      } else {
        router.push(userSignupStep);
      }
    } else {
      localStorage.removeItem(BURNER_CURRENT_STEP);
    }

    if (storageUser && !storageUser.eligibleForPaywall) {
      router.push(ROUTERS.dashboard)
    }

    if (router.location.pathname.includes(ROUTERS.premiumPage)) {
      router.push(
        `${ROUTERS.chooseNumber}${router.location.search ? router.location.search : ''}`
      );
    }

    window.addEventListener('unload', this.handleUnload);

    document.addEventListener('visibilitychange', this.handleAuthenticateCheck);
  }

  componentDidUpdate() {
    $(document).foundation();
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    window.removeEventListener('unload', this.handleUnload);
  }

  handleUnload = () => {
    localStorage.setItem(BURNER_PAGE_STATE, 'unload');
  };

  handleAuthenticateCheck = () => {
    const { router } = this.context;
    const paymentStatus = localStorage.getItem('payment_status');
    const user = getCurrentUser();

    if (paymentStatus === 'failed') {
      router.push(ROUTERS.paywall);
      this.props.subscribePaymentFailed();
    } else if (user && !user.eligibleForPaywall) {
      router.push(ROUTERS.dashboard);
    }
  }

  render() {
    const { children } = this.props;

    return (
      <div className="u-height-full">
        {children}
      </div>
    );
  }
}

App.propTypes = propTypes;
App.defaultProps = defaultProps;
App.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(null, {...dashboardActions, ...subscribeAction})(App);
