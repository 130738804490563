import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as progressBar from '../ducks/progressBar';

import Container from '../components/common/Container';
import QuoteBox from '../components/social-proof/QuoteBox';
import Button from '../components/form/button/Button';
import FeaturedIn from '../components/social-proof/FeaturedIn';
import { checkUserAuth, setUserSignUpStep } from '../utils/sessionStorage';
import { AMPLITUDE_SOCIAL_PROOF_VIEW, ROUTERS } from '../constants';
import * as amplitude from '@amplitude/analytics-browser';
import { amplitudeDeviceProperty } from '../utils/analytics';

import '!style-loader!css-loader!sass-loader!../../styles/social-proof.scss';
import withRegistrationCheck from '../components/WithRegistrationCheck';

class SocialProof extends Component {
  constructor(props) {
    super(props);
    this.handleNext = this.handleNext.bind(this);
  }

  componentDidMount() {
    const { currentStep, setCurrentStep } = this.props;
    const { router } = this.context;

    setUserSignUpStep(ROUTERS.socialProof);
    checkUserAuth(router);

    amplitude.track(AMPLITUDE_SOCIAL_PROOF_VIEW, {
      ...amplitudeDeviceProperty,
    });

    if (currentStep < 3) {
      setCurrentStep(3);
    }
  }

  handleNext() {
    const { router } = this.context;

    router.push(ROUTERS.numberCongrats);
  }

  render() {
    return (
      <Container
        isShowBar
        classNameWrapper="u-height-full"
      >
        <section
          className="SocialProof__container Main__container Main__container--secondary Main__container--default grid-y gxrid-padding-x grid-x">
          <div className="medium-10 large-8 small-12 u-margin-x-auto u-padding-x-container">
            <h1 className="Header__title SocialProof--title medium align-center-middle hide-for-small show-for-medium">
              19+ million people have tried
              Burner
            </h1>
            <h2 className="Header__title SocialProof--title medium align-center-middle shhow-for-small hide-for-medium">
              19+ million have tried
              Burner
            </h2>
            <QuoteBox
              text="The best way to add an additional phone number without the additional cost of a separate phone, period."
              author="Mossy O."
            />
          </div>
          <FeaturedIn />
          <Button
            text="Continue"
            type="submit"
            withArrow
            className="small-12 medium-4 u-margin-x-auto"
            onClick={this.handleNext}
          />
        </section>
      </Container>
    );
  }
}

SocialProof.contextTypes = {
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    selectedNumber: state.chooseNumberPage.selectedNumber,
    currentStep: state.progressBar.currentStep,
  };
}

export default connect(mapStateToProps, {...progressBar})(withRegistrationCheck(SocialProof));
