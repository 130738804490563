import axios from 'axios';
import cookie from 'react-cookie';
import {
  AUTHENTICATION_KEY,
  CURRENT_USER,
  BASE_URL,
  V2_BASE_URL,
  DEFAULT_SKU,
  CUSTOM_WEB_EVENT,
  AMPLITUDE_VERIFY_CODE_SENT,
  VERIFY_SENT,
  NUMBERS_RECEIVED,
  AMPLITUDE_CLIENT_KEY,
  defaultSubscribeData,
  IS_SECURED,
  USER_ELIGIBLE_FOR_PAYWALL,
} from '../constants';
import { handleError, RECEIVE_PHONE_NUMBERS } from './chooseNumber';
import {
  fetchVerifyCode,
  SUBSCRIPTION_CREATED,
  RECEIVE_CODE,
  SUBSCRIBE_MODAL_TOGGLED,
  receiveValidateVerifyCode,
} from './verify';
import { hasError, validationCodeError, VERIFY_CODE_ERROR, RESET_VERIFY_CODE_ERROR, clearErrors } from './error';
import { receiveSubscribtions, requesting } from './general';
import { getCountry } from '../utils/phone';
import { getCurrentUser } from '../utils/user';
import { extractError } from '../utils/request';
import * as amplitude from '@amplitude/analytics-browser';
import {
  amplitudeDeviceProperty,
  trackAmplitudeFailedEvent,
} from '../utils/analytics';
import {
  addStorageSelectedNumber,
  getStoragePlanData,
} from '../utils/sessionStorage';
import {sampleCreated} from './auth';
import {Experiment} from '@amplitude/experiment-js-client';

export const RESET_PHONE_NUMBER_SEARCH = 'burner-app/subscribe/RESET_PHONE_NUMBER_SEARCH';
export const SET_SUBSCRIPTION_PLAN = 'burner-app/subscribe/SET_SUBSCRIPTION_PLAN';
export const SET_SELECTED_NUMBER = 'burner-app/subscribe/SET_SELECTED_NUMBER';
export const NUMBER_IS_TAKEN = 'burner-app/subscribe/NUMBER_IS_TAKEN';
export const NUMBER_ASSOCIATED = 'burner-app/subscribe/NUMBER_ASSOCIATED';
export const PHONE_DOES_NOT_EXIST = 'Could not find a phone number.';
export const SET_PAYMENT_ERROR = 'burner-app/subscribe/SET_PAYMENT_ERROR'
export const CHECK_USER_SUBSCRIPTION = 'burner-app/subscribe/CHECK_USER_SUBSCRIPTION';
export const SUBSCRIBE_MANAGE_NEXT_STEP = 'burner-app/subscribe/SUBSCRIBE_MANAGE_NEXT_STEP';
export const SUBSCRIBE_LIST = 'burner-app/subscribe/SUBSCRIBE_LIST';
export const SUBSCRIBE_LIST_FETCH_START = 'burner-app/subscribe/SUBSCRIBE_LIST_FETCH_START';

const experiment = Experiment.initializeWithAmplitudeAnalytics(AMPLITUDE_CLIENT_KEY);

export default function reducer(state = { sku: 'com.adhoclabs.burner.subscription.month.1' }, action) {
  switch (action.type) {
    case SUBSCRIPTION_CREATED:
      return Object.assign({}, state, {
        subscription: action.response,
        userEmail: action.email,
        accountPhoneNumber: action.accountPhoneNumber,
      });
    case RECEIVE_CODE:
      return Object.assign({}, state, {
        sku: action.sku || DEFAULT_SKU, // needed for subscribe page
      });
    case RECEIVE_PHONE_NUMBERS:
      return Object.assign({}, state, {
        numbers: action.numbers,
      });
    case RESET_PHONE_NUMBER_SEARCH:
      return Object.assign({}, state, {
        numbers: null,
        selectedSku: null,
        numberAssociated: false,
        selectedNumber: null,
      });
    case SET_SELECTED_NUMBER:
      return Object.assign({}, state, {
        selectedNumber: action.selectedNumber,
      });
    case NUMBER_IS_TAKEN:
      return Object.assign({}, state, {
        numbers: state.numbers?.filter((item) => item.phoneNumber !== state.selectedNumber),
        selectedNumber: null,
        isNumberTaken: true,
        errorNumber: true,
      });
    case VERIFY_CODE_ERROR:
      return Object.assign({}, state, {
        verifyErrorMessage: action.error,
      });
    case RESET_VERIFY_CODE_ERROR:
      return Object.assign({}, state, {
        verifyErrorMessage: null,
      });
    case NUMBER_ASSOCIATED:
      return Object.assign({}, state, {
        numberAssociated: true,
        selectedNumber: action.selectedNumber,
      });
    case SET_SUBSCRIPTION_PLAN:
      return Object.assign({}, state, {
        selectedSku: action.sku,
        price: action.price,
      });
    case SET_PAYMENT_ERROR:
      return Object.assign({}, state, {
        paymentError: action.paymentError,
      });
    case CHECK_USER_SUBSCRIPTION:
      return Object.assign({}, state, {
        isSubscribeUser: action.isSubscribeUser,
        isAccessToNextStep: action.isAccessToNextStep,
      });
    case SUBSCRIBE_MANAGE_NEXT_STEP :
      return Object.assign({}, state, {
        isAccessToNextStep: action.isAccessToNextStep,
      });
    case SUBSCRIBE_LIST :
      return Object.assign({}, state, {
        isFetching: false,
        subscriptionData: action.subscriptionData,
      });
    case SUBSCRIBE_LIST_FETCH_START: {
      return Object.assign({}, state, {
        isFetching: true,
      });
    }
    default:
      return state;
  }
}

export function redirectToNextSubscribePage(isAccessToNextStep) {
  return {
    type: SUBSCRIBE_MANAGE_NEXT_STEP,
    isAccessToNextStep,
  }
}

function setSubscribeExist(isSubscribeUser, isAccessToNextStep) {
  return {
    type: CHECK_USER_SUBSCRIPTION,
    isSubscribeUser,
    isAccessToNextStep,
  }
}

export function setPaymentError(paymentError) {
  return {
    type: SET_PAYMENT_ERROR,
    paymentError,
  };
}

export function selectSubscriptionPlan(plan) {
  return {
    type: SET_SUBSCRIPTION_PLAN,
    sku: plan.plan_sku_id,
    price: plan.plan_price,
  };
}

export function setSelectedNumber(selectedNumber) {
  return {
    type: SET_SELECTED_NUMBER,
    selectedNumber,
  };
}

/**
 * Dispatches an event to state that the verify modal has toggled on, so it doesn't toggle again (unless user closes it).
 */
export function modalToggled() {
  return {
    type: SUBSCRIBE_MODAL_TOGGLED,
  };
}

export function resetSearch() {
  return {
    type: RESET_PHONE_NUMBER_SEARCH,
  };
}

function numberIsTaken() {
  return {
    type: NUMBER_IS_TAKEN,
  };
}

function numberAssociated(selectedNumber) {
  return {
    type: NUMBER_ASSOCIATED,
    selectedNumber,
  };
}


export function associateSelectedNumberWithSub(selectedNumber, subscription) {
  const userId = getCurrentUser().id;
  const authToken = cookie.load(AUTHENTICATION_KEY);

  return (dispatch) => {
    dispatch(requesting());

    return axios
      .post(
        `${V2_BASE_URL}/user/${userId}/burners`,
        {
          phoneNumber: selectedNumber,
          subscriptionId: subscription.id,
          useSip: false,
        },
        {
          headers: {
            Authentication: authToken,
            'Content-Type': 'application/json',
          },
        }
      )
      .then(() => {
        localStorage.removeItem(USER_ELIGIBLE_FOR_PAYWALL);
        dispatch(numberAssociated(selectedNumber));
      })
      .catch((error) => {
        localStorage.removeItem(USER_ELIGIBLE_FOR_PAYWALL);
        if (error.data && error.data.error && PHONE_DOES_NOT_EXIST === error.data.error) {
          dispatch(numberIsTaken());
        } else {
          dispatch(hasError(extractError(error), error.status));
        }
      });
  };
}

/**
 * Will try to associate a phone number to a user's account. Happens when the initial number failed to be associated with the burner.
 */
export function reprovisionNumber(selectedNumber, subscription) {
  return (dispatch) => {
    dispatch(setSelectedNumber(selectedNumber));
    return dispatch(associateSelectedNumberWithSub(selectedNumber, subscription));
  };
}

function handleSubscriptionCreated(email, accountPhoneNumber, response) {
  return {
    type: SUBSCRIPTION_CREATED,
    accountPhoneNumber,
    email,
    response,
  };
}

export function createSubscription(formData, stripeResponse) {
  const userId = getCurrentUser().id;
  const authToken = cookie.load(AUTHENTICATION_KEY);

  const body = {
    token: stripeResponse.id,
    email: formData.email,
    firstName: formData.firstName,
    lastName: formData.lastName,
    sku: formData.sku || getStoragePlanData('sku'),
  };

  return (dispatch) => {
    dispatch(requesting());
    return axios
      .post(`${BASE_URL}/user/${userId}/subscriptions/stripe`, body, {
        headers: {
          Authentication: authToken,
          'Content-Type': 'application/json',
        },
      })
      .then((resp) => {
        dispatch(handleSubscriptionCreated(formData.email, formData.phoneNumber, resp.data));
        if (formData.selectedNumber) {
          // if the user has chosen a number, then also make a call to associate the number with the new sub
          dispatch(associateSelectedNumberWithSub(formData.selectedNumber, resp.data));
        } else {
          trackAmplitudeFailedEvent('Oops. Something is wrong with selected number. Try again', 'server');
          dispatch(hasError('Oops. Something is wrong with selected number. Try again'))
        }
      })
      .catch((error) => {
        trackAmplitudeFailedEvent(extractError(error), 'stripe');

        dispatch(setPaymentError('Ops. Something is wrong with your payment method. Try again'))
      });
  };
}

export function hasSubscriptionCheck() {
  const userId = getCurrentUser()?.id;
  const isSubscriptionAllowed = getCurrentUser()?.eligibleForPaywall;

  return (dispatch) => {
    dispatch(requesting());

    return axios
      .get(`${BASE_URL}/user/${userId}/subscriptions`, {
        headers: {
          Authentication: cookie.load(AUTHENTICATION_KEY),
        },
      })
      .then((resp) => {
        // control access to the next page for the user with different access.
        // User with expired sub/user with active mobile sub/uncompleted sub account

        if (resp.data.length > 0) {
          dispatch(receiveSubscribtions(resp.data));
          dispatch(setSubscribeExist(true, true));
        } else if (isSubscriptionAllowed) {
          dispatch(setSubscribeExist(false, true));
        } else if (!isSubscriptionAllowed && !resp.data.length) {
          const cookies = cookie.load(CURRENT_USER);
          const updatedObject = { ...cookies, eligibleForPaywall: true };
          cookie.save(CURRENT_USER, JSON.stringify(updatedObject), { path: '/', secure: IS_SECURED, maxAge: 604800 });
          dispatch(sampleCreated());

          dispatch(setSubscribeExist(false, true));
        }

        sessionStorage.removeItem(VERIFY_SENT);
      })
      .catch((error) => {
        trackAmplitudeFailedEvent(extractError(error));
        dispatch(hasError(extractError(error), error.status))
      });
    }
}

/**
 * Handles when a user clicks on the handle verify submit. Will call backend to get the user and token, then stripe to get auth token. Finally, will call backend with the token
 */
export function validateCodeAndRegister(phone, pin) {
  return (dispatch) => {
    dispatch(requesting());

    return axios
      .post(
        `${BASE_URL}/register`,
        {
          country: getCountry(phone),
          phoneNumber: phone,
          code: pin,
          trackingId: '',
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then((resp) => {
        const authToken = resp.headers.authentication;

        amplitude.setUserId(resp.data.id);

        dispatch(receiveValidateVerifyCode(authToken, resp.data))
        // check to see if user has a subscription already
        dispatch(hasSubscriptionCheck());
      })
      .catch((error) => {
        if (extractError(error)?.includes('expired')) {
          trackAmplitudeFailedEvent('expired verification code');
        } else {
          trackAmplitudeFailedEvent('invalid phone verification - signup');
        }

        dispatch(validationCodeError(extractError(error)))
      });
  };
}

/**
 * Handles when a user clicks on the submit. Will call the backend for VC
 * validation, then if that's successful will pop up the verify code modal
 */
export function handleSubmit(phoneNumber) {
  amplitude.track(AMPLITUDE_VERIFY_CODE_SENT, amplitudeDeviceProperty);
  return fetchVerifyCode(phoneNumber, false);
}

/**
 * Handles a resend verify code action
 */
export function resendVerifyCode(number) {
  amplitude.track(AMPLITUDE_VERIFY_CODE_SENT, amplitudeDeviceProperty);
  return fetchVerifyCode(number, true, amplitude.getDeviceId());
}

function receiveNumbers(numbers) {
  return {
    type: RECEIVE_PHONE_NUMBERS,
    numbers,
  };
}
function receiveSubscribeOptions(subscriptionData) {
  return {
    type: SUBSCRIBE_LIST,
    subscriptionData,
  };
}

function requestSubscribeOptions() {
  return {
    type: SUBSCRIBE_LIST_FETCH_START,
  };
}

export function requestNumber(areaCode) {
  return (dispatch) => {
    dispatch(requesting());

    // gets the user first, then gets the list of burners for that user
    return axios
      .get(`${V2_BASE_URL}/availableNumbers?areaCode=${areaCode}`, {})
      .then((resp) => {
        dispatch(receiveNumbers(resp.data));

        /*clear information about global errors as we begin the registration process from scratch */
        dispatch(clearErrors());
      })
      .catch(() => {
        dataLayer.push({
          event: CUSTOM_WEB_EVENT,
          customWebEventName: NUMBERS_RECEIVED,
          customWebEventAction: false,
          customWebEventLabel: 'invalid area code',
        });
        trackAmplitudeFailedEvent('invalid area code');

        dispatch(handleError('Whoops! That is not a valid area code.'));
      })
  };
}

export function requestAndSelectNumber(areaCode) {
  return (dispatch) => {
    dispatch(requesting());

    // gets the available numbers then selects one
    return axios
      .get(`${V2_BASE_URL}/availableNumbers?areaCode=${areaCode}`, {})
      .then((resp) => {
        dispatch(receiveNumbers(resp.data));
        dispatch(setSelectedNumber(resp.data[0].phoneNumber));
      })
      .catch((error) => {
        trackAmplitudeFailedEvent(extractError(error));

        dispatch(hasError(extractError(error), error.status, false, '/areaCode'));
      });
  };
}
export function setChooseNumber(phoneNumber) {
  return (dispatch) => {
    addStorageSelectedNumber(phoneNumber)

    dispatch(setSelectedNumber(phoneNumber));
  }
}

// cal this reducer when user navigate between tabs
export function subscribePaymentFailed() {
  return (dispatch) => {
    dispatch(hasError('Oops. Something is wrong with selected number. Try again'))
  }
}

export function fetchSubscribeDetails() {
  return (dispatch) => {
    dispatch(requesting());
    dispatch(requestSubscribeOptions());

    const storageUser = getCurrentUser();

    // need for received an uniq data for each user
    const user = {
      user_id: storageUser?.id,
    };

    return experiment.fetch(user).then((response) => {
      const experimentOption = response.variant('web_paywall_remote_config');

      if (experimentOption?.value !== 'off' && experimentOption?.payload) {
        dispatch(receiveSubscribeOptions(experimentOption.payload))
      } else {
        dispatch(receiveSubscribeOptions(defaultSubscribeData))
      }
    })
  }
}
