import React from 'react';

import '!style-loader!css-loader!sass-loader!../../../styles/what-you-get.scss'
import InfinityIcon from '../icons/paywall/infinity';
import NoSimIcon from '../icons/paywall/noSim';
import SpamBlockingIcon from '../icons/paywall/spamBlocking';
import MuteIcon from '../icons/paywall/mute';
import TrashIcon from '../icons/paywall/trash';
import ReplyTextIcon from '../icons/paywall/replyText';
import {Grid} from '@mui/material';
import AddsFreeIcon from '../icons/paywall/adFree';

const getItems = [
  {
    component: InfinityIcon,
    text: 'Unlimited calls & texts',
  },
  {
    component: NoSimIcon,
    text: 'No SIM needed',
  },
  {
    component: SpamBlockingIcon,
    text: 'Call spam blocking',
  },
  {
    component: MuteIcon,
    text: 'Muting & blocking',
  },
  {
    component: TrashIcon,
    text: 'Delete or switch numbers',
  },
  {
    component: ReplyTextIcon,
    text: 'Auto-reply to texts',
  },
  {
    component: AddsFreeIcon,
    text: '100% ad-free',
  },
];

function WhatYouGet() {
  return (
    <section className="WhatYouGet__container grid-x align-center-middle">
      <div className="Max__container">
        <div className="WhatYouGet__header">
          <span className="WhatYouGet__divider"/>
          <h1 className="WhatYouGet__title">WHAT YOU GET</h1>
          <span className="WhatYouGet__divider"/>
        </div>
        <Grid className="WhatYouGet__column" container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {getItems.map(({ component: Icon, text }, index) => (
            <Grid
              className="WhatYouGet__cell"
              item xs={12} sm={6} lg={4} key={index}
            >
              <Icon />
              <p className="WhatYouGet__cell--text">{text}</p>
            </Grid>
          ))}
        </Grid>
      </div>
    </section>
  )
}

export default React.memo(WhatYouGet);
