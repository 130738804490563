import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import * as progressBar from '../ducks/progressBar';

import Container from '../components/common/Container';
import Button from '../components/form/button/Button';
import { checkUserAuth, setUserSignUpStep } from '../utils/sessionStorage';
import withRegistrationCheck from '../components/WithRegistrationCheck';
import { renderLocalNumber } from '../utils/phone';
import { AMPLITUDE_CONGRATS_NUMBER_VIEW, ROUTERS, SELECTED_NUMBER } from '../constants';
import { amplitudeDeviceProperty } from '../utils/analytics';
import * as amplitude from '@amplitude/analytics-browser';

import '!style-loader!css-loader!sass-loader!../../styles/number-congrats.scss'

class NumberCongratsPage extends Component {
  constructor(props) {
    super(props);

    this.handleNext = this.handleNext.bind(this);
  }

  componentDidMount() {
    const { router } = this.context;

    const { currentStep, setCurrentStep } = this.props;
    setUserSignUpStep(ROUTERS.numberCongrats);

    if (currentStep < 3) {
      setCurrentStep(3);
    }

    amplitude.track(AMPLITUDE_CONGRATS_NUMBER_VIEW, {
      ...amplitudeDeviceProperty,
    });

    checkUserAuth(router);
  }

  handleNext() {
    const { router } = this.context;

    router.push(ROUTERS.paywall);
  }

  render() {
    const { selectedNumber } = this.props;
    const storageNumber = localStorage.getItem(SELECTED_NUMBER);
    const formatNumber = renderLocalNumber(storageNumber || selectedNumber)

    return (
      <Container isShowBar classNameWrapper="u-height-full">
        <div className="NumberCongrats__container Main__container Main__container--minHeight Main__container--secondary grid-y grid-padding-x align-center-middle">
          <h1 className="Header__title">Congrats!</h1>
          <h2 className="Header__subtitle PickNumber__subscribe--subtitle h5">Here is your new number:</h2>
          <p className="NumberCongrats__number medium h3">{formatNumber}</p>
          <Button
            text="Continue"
            type="button"
            withArrow
            className="show-for-medium hide-for-small"
            onClick={this.handleNext}
          />
        </div>
        <div className="hide-for-medium show-for-small NumberCongrats--button__footer">
          <Button
            text="Activate my number"
            type="button"
            withArrow
            onClick={this.handleNext}
          />
        </div>
      </Container>
    );
  }
}

NumberCongratsPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    selectedNumber: state.chooseNumberPage.selectedNumber,
    currentStep: state.progressBar.currentStep,
  };
}


export default connect(mapStateToProps, {...progressBar})(withRegistrationCheck(NumberCongratsPage));
