import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cookie from 'react-cookie';

import { AUTHENTICATION_KEY, BURNER_PAGE_STATE, ROUTERS, USER_ELIGIBLE_FOR_PAYWALL } from '../constants';
import { getCurrentUser } from '../utils/user';

import * as dashboardActions from '../ducks/dashboard';

function withRegistrationCheck(WrappedComponent) {
  class RegistrationCheck extends Component {
    static contextTypes = {
      router: PropTypes.object.isRequired,
    };

    async componentDidMount() {
      const { router } = this.context;
      const { loadUserInfo } = this.props;
      const authToken = cookie.load(AUTHENTICATION_KEY);
      const isFetchingStripeSecret = localStorage.getItem(BURNER_PAGE_STATE);
      const userPaywall = localStorage.getItem(USER_ELIGIBLE_FOR_PAYWALL)

      if (!authToken || userPaywall === 'true') {
        return;
      }

      try {
        const user = await getCurrentUser();

        if (!user) {
          return;
        }

        if (isFetchingStripeSecret && user.eligibleForPaywall) {
         await loadUserInfo(authToken, user.id);
        }

        const redirectPath = this.getRedirectPath(user);

        if (redirectPath) {
          router.push(redirectPath);
        }
      } catch (err) {
        console.error('Error during registration check:', err);
      }
    }

    getRedirectPath(user) {
      if (!user.eligibleForPaywall) {
        return ROUTERS.dashboard;
      }

      return null;
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  RegistrationCheck.propTypes = {
    loadUserInfo: PropTypes.func.isRequired,
  };

  const mapStateToProps = (state) => ({
    userProps: state.auth.user,
  });

  const mapDispatchToProps = {
    loadUserInfo: dashboardActions.loadUserInfo,
  };

  return connect(mapStateToProps, mapDispatchToProps)(RegistrationCheck);
}

export default withRegistrationCheck;
