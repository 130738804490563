import React from 'react';
import { Checkbox } from '@mui/material';
import classNames from 'classnames';
import { arrayOf, func, shape, string } from 'prop-types';

import MarkIcon from '../icons/paywall/markIcon';

class SubscriptionCheckboxV2 extends React.Component {
  render() {
    const { subscribeOptions, value, onChange } = this.props;

    return subscribeOptions.map(subscription => {
      const isChecked = subscription.plan_sku_id === value;

      return (
        <label className="u-relative" key={subscription.plan_sku_id}>
          <div className={classNames('Paywall__subscriptions--plan Paywall__subscriptions--plan-v2 grid-y', isChecked && 'active-v2')}>
            {!!Object.keys(subscription).includes('plan_label') && <span className="Paywall__plan--popular-v2">{subscription.plan_label}</span>}
            <div className="grid-x Paywall__grid-v2">
              <div className="Paywall__plan--container-v2 grid-x align-justify">
                <h3 className="Paywall__plan--title-v2 bold">{subscription.plan_title}</h3>
              </div>
              <p className="Paywall__plan--subtitle Paywall__plan--subtitle-v2">
                <span>{subscription.plan_subtitle}</span>
              </p>
            </div>
          </div>
          <div>
            <Checkbox
              value={subscription.plan_sku_id}
              sx={{'& .MuiSvgIcon-root': {fontSize: 24}}}
              checked={isChecked}
              onChange={() => onChange(subscription)}
              checkedIcon={
                <MarkIcon />
              }
            />
          </div>
        </label>
      )
    })
  }
}

SubscriptionCheckboxV2.propTypes = {
  value: string.isRequired,
  onChange: func.isRequired,
  subscribeOptions: arrayOf(shape({
    plan_title: string.isRequired,
    plan_sku_id: string.isRequired,
    plan_subtitle: string,
    plan_label: string,
  })).isRequired,
}

export default React.memo(SubscriptionCheckboxV2);
