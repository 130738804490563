import {
  BURNER_CURRENT_STEP, CURRENT_USER,
  IS_SECURED, ROUTERS,
  SELECTED_NUMBER,
  SELECTED_PLAN,
  USER_ELIGIBLE_FOR_PAYWALL,
  USER_PHONE_NUMBER,
  VERIFY_SENT,
} from '../constants';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { getCurrentUser } from './user';
import cookie from 'react-cookie';

export function clearStorage() {
  sessionStorage.removeItem(USER_PHONE_NUMBER);
  sessionStorage.removeItem(VERIFY_SENT);
  sessionStorage.removeItem(SELECTED_PLAN);
  localStorage.removeItem(SELECTED_NUMBER);
}

export const defaultSessionOption = {
  path: '/',
  secure: IS_SECURED,
  maxAge: 604800,
}

export function addStorageSelectedNumber(phoneNumber) {
  localStorage.setItem(SELECTED_NUMBER, phoneNumber);
}

export function addStorageSubscription(plan) {
  const planData = {
    sku: plan.plan_sku_id,
    ...plan,
  }

  sessionStorage.setItem(SELECTED_PLAN, JSON.stringify(planData));
}

export function removeStorageData(value) {
  return sessionStorage.removeItem(value);
}

export function getStoragePlanData(name) {
  const retrievedData = sessionStorage.getItem(SELECTED_PLAN);
  const parsedData = JSON.parse(retrievedData);

  if (name && parsedData) {
    return parsedData[name];
  }

  return parsedData;
}

export function setStoragePhone(number) {
  sessionStorage.setItem(USER_PHONE_NUMBER, number);
}

export function getStoragePhoneData() {
  const phone = sessionStorage.getItem(USER_PHONE_NUMBER);

  if (phone) {
    return parsePhoneNumberFromString(phone);
  }

  return null;
}

export function setStorageVerifyPage(value) {
  sessionStorage.setItem(VERIFY_SENT, value);
}

export function getStorageData(storageValue) {
  return sessionStorage.getItem(storageValue);
}

export function setUserSignUpStep(path) {
  localStorage.setItem(BURNER_CURRENT_STEP, path)
}

// return user to home page if one doesn't authenticate
export function checkUserAuth(router) {
  const user = getCurrentUser();
  const selectedNumber = localStorage.getItem(SELECTED_NUMBER);

  if (!user || !selectedNumber) {
    router.push(ROUTERS.homePage);
  }
}


export function storageEligibleForPaywall() {
  localStorage.setItem(USER_ELIGIBLE_FOR_PAYWALL, 'true')

  const cookies = cookie.load(CURRENT_USER);
  const updatedObject = { ...cookies, eligibleForPaywall: true };
  cookie.save(CURRENT_USER, JSON.stringify(updatedObject), { path: '/', secure: IS_SECURED, maxAge: 604800 });
}
