import axios from 'axios';
import cookie from 'react-cookie';
import {
  AUTHENTICATION_KEY,
  CURRENT_USER,
  BASE_URL,
  AMPLITUDE_LOGIN_SUCCESS, VERIFY_SENT,
} from '../constants';
import { hasError, validationCodeError, VERIFY_CODE_ERROR, RESET_VERIFY_CODE_ERROR } from './error';
import { requesting, REQUESTING } from './general';
import { getCountry } from '../utils/phone';
import { getCurrentUser } from '../utils/user';
import { extractError } from '../utils/request';
import { amplitudeDeviceProperty, trackAmplitudeFailedEvent } from '../utils/analytics';
import * as amplitude from '@amplitude/analytics-browser';
import {CHECK_USER_SUBSCRIPTION, setPaymentError} from './subscribe';
import {defaultSessionOption} from '../utils/sessionStorage';

export const RECEIVE_CODE = 'burner-app/verify/RECEIVE_CODE';
export const USER_VERIFIED = 'burner-app/verify/USER_VERIFIED';
export const LOGOUT_SUCCESS = 'burner-app/verify/LOGOUT_SUCCESS';
export const SUBSCRIPTION_CREATED = 'burner-app/verify/SUBSCRIPTION_CREATED';
export const SUBSCRIBE_MODAL_TOGGLED = 'burner-app/verify/SUBSCRIBE_MODAL_TOGGLED';
export const RESEND_MODAL_TOGGLED = 'burner-app/verify/RESEND_MODAL_TOGGLED';
export const CLEAR_VERIFY_CODE = 'burner-app/verify/CLEAR_VERIFY_CODE';

/**
 * Reducer for the verify/login page
 */
export default function reducer(state = { isFetching: false }, action = {}) {
  switch (action.type) {
    case REQUESTING:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case RECEIVE_CODE:
      return Object.assign({}, state, {
        codeWasSent: true,
        isFetching: false,
        isResend: action.isResend,
        showResendOptionsModal: false,
      });
    case USER_VERIFIED:
      return Object.assign({}, state, {
        verifyErrorMessage: null,
        userVerified: true,
        isFetching: false,
        codeWasSent: false,
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        userVerified: false,
      });
    case SUBSCRIPTION_CREATED:
      return Object.assign({}, state, {
        codeWasSent: false, // disable this once sub is successfully created
      });
    case VERIFY_CODE_ERROR:
      return Object.assign({}, state, {
        verifyErrorMessage: action.error,
        isFetching: false,
        codeWasSent: true,
        showResendOptionsModal: false,
      });
    case RESET_VERIFY_CODE_ERROR:
      return Object.assign({}, state, {
        verifyErrorMessage: null,
      });
    case SUBSCRIBE_MODAL_TOGGLED:
    case CLEAR_VERIFY_CODE:
      return Object.assign({}, state, {
        codeWasSent: false,
      });
    case RESEND_MODAL_TOGGLED:
      return Object.assign({}, state, {
        showResendOptionsModal: !state.showResendOptionsModal,
    })
    case CHECK_USER_SUBSCRIPTION:
      return Object.assign({}, state, {
        isFetching: false,
        codeWasSent: false,
      });
    default:
      return state;
  }
}

export function toggleResendModal() {
  return {
    type: RESEND_MODAL_TOGGLED,
  };
}

/**
 * Happens when network request comes through for requesting the verify code
 */
function receiveVerifyCode(isResend) {
  return {
    type: RECEIVE_CODE,
    receivedAt: Date.now(),
    isResend,
  };
}

/**
 * Fetches the verify code. Note that sku is optional, to be sent along to the receive verify code dispatch.
 */
export function fetchVerifyCode(phoneNumber, isResend = false, deviceId, errorAction = null, authToken) {
  const errorActionHash = { goBack: errorAction, redirectBackAction: errorAction };
  const authHeader = authToken ? { Authentication: authToken } : {};

  return (dispatch) => {
    dispatch(requesting());
    return axios
      .post(
        `${BASE_URL}/verifyCode`,
        {
          phoneNumber,
          deviceId,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            ...authHeader,
          },
        }
      )
      .then(() => {
        dispatch(receiveVerifyCode(isResend));
        dispatch(setPaymentError(null));
      })
      .catch((error) => {
        trackAmplitudeFailedEvent('unsupported carrier');
        sessionStorage.removeItem(VERIFY_SENT);

        if (isResend) {
          dispatch(toggleResendModal());
        }

        dispatch(setPaymentError(null));
        dispatch(Object.assign({},
          hasError(extractError(error),
            error.status,
            null,
            errorActionHash.redirectBackAction
          )
        ))
      });
  };
}

function userVerified() {
  return {
    type: USER_VERIFIED,
    authToken: cookie.load(AUTHENTICATION_KEY),
    user: getCurrentUser(),
  };
}

// will save the authentication token and user info
export function receiveValidateVerifyCode(authToken, user) {
  return (dispatch) => {
    // save the authToken
    cookie.save(AUTHENTICATION_KEY, authToken, defaultSessionOption);
    // save the user
    cookie.save(CURRENT_USER, user, defaultSessionOption);
    dispatch(userVerified());
  };
}

export function validateVerifyCode(phone, pin) {
  return (dispatch) => {
    dispatch(requesting());

    return axios
      .post(
        `${BASE_URL}/register`,
        {
          country: getCountry(phone),
          phoneNumber: phone,
          code: pin,
          trackingId: '',
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then((resp) => {
        const authHeader = resp.headers.authentication;

        if (authHeader) {
          amplitude.track(AMPLITUDE_LOGIN_SUCCESS, {
            provider: 'phone',
            method: 'phone',
            ...amplitudeDeviceProperty,
          })
          amplitude.setUserId(resp.data.id);

          dispatch(receiveValidateVerifyCode(authHeader, resp.data));

          return { authToken: authHeader, user: resp.data };
        }
      })
      .catch((error) => {
        if (extractError(error)?.includes('expired')) {
          trackAmplitudeFailedEvent('expired verification code');
        } else {
          trackAmplitudeFailedEvent('invalid phone verification - signin');
        }

        dispatch(validationCodeError(extractError(error)));
      });
  };
}

export function clearCodeState() {
  return {
    type: CLEAR_VERIFY_CODE,
  }
}
