import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ArrowIcon } from '../../subscribe/ArrowIcon';

function Button({
  onClick,
  type,
  styleType,
  className,
  withArrow = false,
  text,
  icon,
  disabled,
 }) {
  return (
    <button
      className={
        classNames(
          'button button--withArrow u-width-full',
          className,
          styleType === 'white' && 'button--white'
        )
      }
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {icon}
      <span className="Form__submit--button">{text}</span>
      {withArrow && <ArrowIcon width={16} height={16} fill={'#111111'}/>}
    </button>
  )
}

Button.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['button', 'submit', 'reset']).isRequired,
  styleType: PropTypes.string,
  className: PropTypes.string,
  withArrow: PropTypes.bool,
  text: PropTypes.string.isRequired,
  icon: PropTypes.element,
  disabled: PropTypes.bool,
}

Button.defaultProps = {
  text: 'Continue',
}

export default React.memo(Button);
