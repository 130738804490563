import axios from 'axios';
import cookie from 'react-cookie';
import { CURRENT_USER, BASE_URL } from '../constants';
import { LOGOUT_SUCCESS } from './verify';
import { hasError } from './error';
import { clearUserData as ClearUserFromState, LOGGED_IN, receivedUserData } from './auth';
import {
  requesting,
  getSubscriptionInfo,
  getBurnersList,
  receivePaymentInfo,
  RECEIVE_BURNERS_LIST,
  clearUserInfo,
} from './general';
import { getStripeAuthToken } from './stripe-subscribe';
import { SUBSCRIPTION_CANCELLED } from './successModal';
import { extractError } from '../utils/request';
import { clearStorage, defaultSessionOption } from '../utils/sessionStorage';

export const RECEIVE_SUBSCRIPTIONS = 'burner-web/dashboard/RECEIVE_SUBSCRIPTIONS';
export const CANCEL_SUBSCRIPTION_CLICKED = 'burner-web/dashboard/CANCEL_SUBSCRIPTION_CLICKED';
export const DISMISS_CANCEL_SUBSCRIPTION = 'burner-web/dashboard/DISMISS_CANCEL_SUBSCRIPTION';
export const REINSTATE_SUBSCRIPTION_CLICKED = 'burner-web/dashboard/REINSTATE_SUBSCRIPTION_CLICKED';
export const DISMISS_REINSTATE_SUBSCRIPTION = 'burner-web/dashboard/DISMISS_REINSTATE_SUBSCRIPTION';
export const RECEIVE_PAYMENT_INFO = 'burner-web/dashboard/RECEIVE_PAYMENT_INFO';
export const RECEIVE_SUBSCRIPTIONS_INFO = 'burner-web/dashboard/RECEIVE_SUBSCRIPTIONS_INFO';
export const SHOW_CHANGE_PAYMENT_FORM = 'burner-web/dashboard/SHOW_CHANGE_PAYMENT_FORM';
export const HIDE_CHANGE_PAYMENT_FORM = 'burner-web/dashboard/HIDE_CHANGE_PAYMENT_FORM';
export const PAYMENT_UPDATED = 'burner-web/dashboard/PAYMENT_UPDATED';

export default function reducer(state = { subscriptionCancelled: false, showChangePaymentForm: false }, action) {
  switch (action.type) {
    case RECEIVE_BURNERS_LIST:
      return Object.assign({}, state, {
        burners: action.burners,
      });
    case RECEIVE_SUBSCRIPTIONS:
      return Object.assign({}, state, {
        subscriptions: action.subscriptions,
        rules: action.rules,
      });
    case CANCEL_SUBSCRIPTION_CLICKED:
      return Object.assign({}, state, {
        cancelSubscriptionClicked: true,
      });
    case DISMISS_CANCEL_SUBSCRIPTION:
      return Object.assign({}, state, {
        cancelSubscriptionClicked: false,
      });
    case REINSTATE_SUBSCRIPTION_CLICKED:
      return Object.assign({}, state, {
        reinstateSubscriptionClicked: true,
      });
    case DISMISS_REINSTATE_SUBSCRIPTION:
      return Object.assign({}, state, {
        reinstateSubscriptionClicked: false,
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        burners: null,
      });
    case RECEIVE_PAYMENT_INFO:
      return Object.assign({}, state, {
        paymentInfo: action.paymentInfo,
      });
    case SHOW_CHANGE_PAYMENT_FORM:
      return Object.assign({}, state, {
        showChangePaymentForm: true,
      });
    case HIDE_CHANGE_PAYMENT_FORM:
      return Object.assign({}, state, {
        showChangePaymentForm: false,
      });
    case RECEIVE_SUBSCRIPTIONS_INFO:
      return Object.assign({}, state, {
        subscriptions: action.subscriptions,
      });
    default:
      return state;
  }
}

export function clickCancelSubscription() {
  return {
    type: CANCEL_SUBSCRIPTION_CLICKED,
  };
}

export function clickReinstateSubscription() {
  return {
    type: REINSTATE_SUBSCRIPTION_CLICKED,
  };
}

export function toggleChangePaymentForm(curState) {
  return {
    type: curState ? HIDE_CHANGE_PAYMENT_FORM : SHOW_CHANGE_PAYMENT_FORM,
  };
}

export function dismissCancelSubsModal() {
  return {
    type: DISMISS_CANCEL_SUBSCRIPTION,
  };
}

export function dismissReinstateSubsModal() {
  return {
    type: DISMISS_REINSTATE_SUBSCRIPTION,
  };
}

export function showSuccessModal(message) {
  return {
    type: PAYMENT_UPDATED,
    message,
  };
}

export function hideChangePaymentForm() {
  return {
    type: HIDE_CHANGE_PAYMENT_FORM,
  };
}

function userLoggedIn(user) {
  return {
    type: LOGGED_IN,
    user,
  };
}

export function loadUserDetails(authToken, userId, callback) {
  return (dispatch) => {
    dispatch(requesting());

    // gets the user first, then gets the list of burners for that user
    axios
      .get(`${BASE_URL}/user/${userId}`, {
        headers: {
          Authentication: authToken,
          'Cache-Control': 'no-cache, no-store, must-revalidate',
        },
      })
      .then((resp) => {
        cookie.save(CURRENT_USER, resp.data, defaultSessionOption);
        dispatch(userLoggedIn(resp.data));
        dispatch(getBurnersList({ authToken, userId }));
        dispatch(getSubscriptionInfo(authToken, userId));
      })
      .catch((error) => {
        dispatch(hasError(extractError(error), error.status, null, callback));
      });
  };
}

// double check user exist or not
export function loadUserInfo(authToken, userId, redirectUrl) {
  return (dispatch) => {
    dispatch(requesting());

    axios
      .get(`${BASE_URL}/user/${userId}`, {
        headers: {
          Authentication: authToken,
        },
      })
      .then((resp) => {
        dispatch(receivedUserData(resp.data))
        cookie.save(CURRENT_USER, resp.data, defaultSessionOption);
      })
      .catch((error) => {
        if (error.status === 401) {
          clearStorage();
          dispatch(ClearUserFromState());
          dispatch(clearUserInfo());

          dispatch(hasError(
            'User is not authorized. Create a new Burner account or login to continue!',
            error.status,
            false,
            redirectUrl
          ));
        } else {
          dispatch(hasError(extractError(error), error.status));
        }
      });
  };
}


function subscriptionCancelled() {
  return {
    type: SUBSCRIPTION_CANCELLED,
    title: 'Your Subscription Has Been Cancelled',
    message:
      '<p>Thanks for being a Burner customer.</p>' +
      '<p>You can continue to use your number until the end of your billing ' +
      'cycle or you can renew your subscription.</p>',
  };
}

export function cancelSubscription(authToken, userId, subscriptionId) {
  return (dispatch) => {
    dispatch(requesting());

    axios
      .delete(`${BASE_URL}/user/${userId}/subscriptions/stripe/${subscriptionId}`, {
        headers: {
          Authentication: authToken,
          'Content-Type': 'application/json',
        },
      })
      .then(() => {
        dispatch(dismissCancelSubsModal());
        dispatch(getSubscriptionInfo(authToken, userId));
        dispatch(subscriptionCancelled());
      })
      .catch((error) => {
        dispatch(dismissCancelSubsModal());
        dispatch(hasError(extractError(error), error.status));
      });
  };
}

export function reinstateSubscription(authToken, userId, subscriptionId) {
  return async (dispatch) => {
    dispatch(requesting());

    try {
      await axios.put(
        `${BASE_URL}/user/${userId}/subscriptions/stripe/${subscriptionId}/reinstate`,
        {},
        {
          headers: {
            Authentication: authToken,
            'Content-Type': 'application/json',
          },
        }
      );

      dispatch(dismissReinstateSubsModal());

      // Waiting for the BE to update the values so that we receive new and up-to-date data
      await new Promise((resolve) => setTimeout(resolve, 4000));

      dispatch(getSubscriptionInfo(authToken, userId));
    } catch (error) {
      dispatch(dismissReinstateSubsModal());
      dispatch(hasError(extractError(error), error.status));
    }
  };
}

function updateStripeToken(stripeInfo, authToken, userId) {
  return (dispatch) => {
    dispatch(requesting());
    return axios
      .post(
        `${BASE_URL}/user/${userId}/subscriptions/stripe/changePaymentMethod`,
        {
          token: stripeInfo.id,
        },
        {
          headers: {
            Authentication: authToken,
          },
        }
      )
      .then((resp) => {
        dispatch(showSuccessModal('Your payment info has been updated'));
        dispatch(hideChangePaymentForm());
        dispatch(receivePaymentInfo(resp.data.sources[0]));
      })
      .catch((error) => {
        dispatch(hasError(extractError(error.message)));
      });
  };
}

export function updatePaymentInfo(params, authToken, userId) {
  return (dispatch) => {
    dispatch(requesting());

    const stripePayload = {
      name: `${params.firstName} ${params.lastName}`,
      number: params.creditCard,
      expMonth: params.expMonth,
      expYear: params.expYear,
      cvc: params.cvc,
      address_zip: params.zipCode,
    };

    return getStripeAuthToken(stripePayload)
      .then((response) => dispatch(updateStripeToken(response, authToken, userId)))
      .catch((error) => dispatch(hasError(extractError(error.message))));
  };
}

export function userIsAuthentificate(redirectUrl) {
  return (dispatch) => {
    clearStorage();

    dispatch(clearUserInfo());
    dispatch(hasError(
      'User is not authorized. Please sign in to continue using the Dashboard.',
      401,
      null,
      redirectUrl
      ))
  };
}
