export const AUTHENTICATION_KEY = 'burner_token';
export const CURRENT_USER = 'burner_user';
export const US = 'US';
export const AU = 'AU';
export const AVAILABLE_COUNTRIES = [
  {
    countryCode: US,
    name: 'United States',
    dialCode: 1,
  },
  {
    countryCode: 'CA',
    name: 'Canada',
    dialCode: 1,
  },
  {
    countryCode: AU,
    name: 'Australia',
    dialCode: 61,
  },
];

let tBASE_URL = 'https://phoenix.burnerapp.com';
if (__DEVELOPMENT__) {
  tBASE_URL = 'https://dev-api.burnerapp.com';
} else if (__QA__) {
  tBASE_URL = 'https://api-qa.burnerapp.com';
} else if (__QA1__) {
  tBASE_URL = 'https://qa1-api.burnerapp.com';
} else if (__QA2__) {
  tBASE_URL = 'https://qa2-api.burnerapp.com';
}

let tV2_BASE_URL = 'https://phoenix.burnerapp.com/v2';
if (__DEVELOPMENT__) {
  tV2_BASE_URL = 'https://dev-api.burnerapp.com/v2';
} else if (__QA__) {
  tV2_BASE_URL = 'https://api-qa.burnerapp.com/v2';
} else if (__QA1__) {
  tV2_BASE_URL = 'https://qa1-api.burnerapp.com/v2';
} else if (__QA2__) {
  tV2_BASE_URL = 'https://qa2-api.burnerapp.com/v2';
}

export const BASE_URL = tBASE_URL;
export const V2_BASE_URL = tV2_BASE_URL;
export const IS_SECURED = __DEVELOPMENT__ || __QA__ || __QA1__ || __QA2__ ? false : true;
export const STRIPE_KEY =
  __DEVELOPMENT__ || __QA__ || __QA1__ || __QA2__
    ? 'pk_test_rLO7bWBqKLeKOQ7FgldSjw9l'
    : 'pk_live_IJdmz3jg8tkhMYiyxi1lI6kq';
export const ENVIRONMENT = __DEVELOPMENT__ || __QA__ || __QA1__ || __QA2__ ? 'QA' : 'Production';
export const GA_KEY = __DEVELOPMENT__ || __QA__ || __QA1__ || __QA2__ ? 'G-QX429K8MXF' : 'G-R146Q8ESM5';
export const DEFAULT_SKU = 'com.adhoclabs.burner.subscription.month.1';
export const OAUTH_REDIRECT_PARAMS = 'oath_redirect_params';
export const SELECTED_NUMBER = 'SELECTED_NUMBER';
export const SELECTED_PLAN = 'SELECTED_PLAN';
export const USER_PHONE_NUMBER = 'USER_PHONE_NUMBER';
export const VERIFY_SENT = 'VERIFY_SENT';
export const BURNER_PAGE_STATE = 'BURNER_PAGE_STATE';
export const BURNER_CURRENT_STEP = 'BURNER_CURRENT_STEP';
export const USER_ELIGIBLE_FOR_PAYWALL = 'userEligibleForPaywall';
export const CHANGE_TAB = 'tab_is_change';
export const STRIPE_PUBLISHABLE_KEY = __DEVELOPMENT__ || __QA__ || __QA1__ || __QA2__ ? 'pk_test_fOgTHhKeubpj5vv2LdmUyv9N' : 'pk_live_YZEGCoEC8CokhwThbjTXqBeA';

// firebase
export const FIREBASE_API_KEY = __DEVELOPMENT__ || __QA__ || __QA1__ || __QA2__ ? 'AIzaSyBZoUF3r7NgsFR_11fz1Sbpd2jEPl-xpB0' : '';
export const FIREBASE_DOMAIN = __DEVELOPMENT__ || __QA__ || __QA1__ || __QA2__ ? 'burner-qa1-1ee81.firebaseapp.com' : '';
export const FIREBASE_DB_URL = __DEVELOPMENT__ || __QA__ || __QA1__ || __QA2__ ? 'https://burner-qa1-1ee81-default-rtdb.firebaseio.com' : '';
export const FIREBASE_PROJECT_ID = __DEVELOPMENT__ || __QA__ || __QA1__ || __QA2__ ? 'burner-qa1-1ee81' : '';
export const FIREBASE_STORAGE_BUCKET = __DEVELOPMENT__ || __QA__ || __QA1__ || __QA2__ ? 'burner-qa1-1ee81.appspot.com' : '';
export const FIREBASE_SENDER_ID = __DEVELOPMENT__ || __QA__ || __QA1__ || __QA2__ ? '237158632483' : '';
export const FIREBASE_APP_ID = __DEVELOPMENT__ || __QA__ || __QA1__ || __QA2__ ? '1:237158632483:web:e77152335e8065ab8bf596' : '';
export const FIREBASE_G_ID = __DEVELOPMENT__ || __QA__ || __QA1__ || __QA2__ ? 'G-BCBBLDMY6J' : '';

// analytics related
export const CUSTOM_WEB_EVENT = 'customWebEvent';
export const CUSTOM_WEB_PAGEVIEW = 'customWebPageview';
export const AMPLITUDE_KEY = __DEVELOPMENT__ || __QA__ || __QA1__ || __QA2__
  ? '535c4199d1abeb892baf471d2e6e81f5' : '73edfffeb77c887aae4c0c2e3c7eb7a6';
export const AMPLITUDE_CLIENT_KEY = __DEVELOPMENT__ || __QA__ || __QA1__ || __QA2__ ?
  'client-iTMeRGKkl0qlEiF9BxzVeFWEjPR7BcBS' : 'client-iYq0K5r5aktADgWObH1dItUZN79DrlIg';

// event names
export const START_FLOW_SQUEEZE_WEB = 'start_flow_squeeze_web';
export const PURCHASED = 'purchase';
export const SIGNUP = 'signup';
export const NUX_NUMBER_VERIFICATION_WEB = 'nux_number_verification_web';
export const NUX_NUMBER_SELECTION_WEB = 'nux_number_selection_web';
export const FIND_NUMBER = 'find_number';
export const NUMBERS_RECEIVED = 'numbers_received';
export const CHOOSE_NUMBER = 'choose_number';
export const RESELECT_AREA_CODE = 'reselect_area_code';
export const SUBMIT_PAYMENT_INFO = 'submit_payment_info';
export const ERROR_EVENT = 'error';
export const PURCHASE_ERROR = 'purchase_error';
export const SUBSCRIPTION_PURCHASE_START = 'subscription_purchase_start';
export const WEB_SUBSCRIPTION_EVENT = 'web_subscription_signup';
export const AMPLITUDE_AREA_CODE_VIEW = 'web_burner_create_area_code_view';
export const AMPLITUDE_PAYWALL_VIEW = 'web_paywall_view';
export const AMPLITUDE_CREATE_ACCOUNT_VIEW = 'web_create_account_view';
export const AMPLITUDE_VERIFY_CODE_SENT = 'web_verify_code_sent';
export const AMPLITUDE_SIGNUP_SUCCESS = 'web_account_sign_up_success';
export const AMPLITUDE_WEB_SUBSCRIPTION_PURCHASE_SUCCESS = 'web_subscription_purchase_success';
export const AMPLITUDE_LOGIN_SUCCESS = 'web_account_log_in_success';
export const AMPLITUDE_PROPERTY_DEVICE = 'web_device_type';
export const AMPLITUDE_WEB_FAILURE = 'web_failure';
export const AMPLITUDE_SOCIAL_PROOF_VIEW = 'web_onboarding_social_proof_view';
export const AMPLITUDE_CONGRATS_NUMBER_VIEW = 'web_onboarding_congrats_number_view';
export const AMPLITUDE_STRIPE_CHECKOUT_VIEW = 'web_stripe_checkout_view';

// oauth scopes
export const OAUTH_MESSAGES_CONNECT = 'messages:connect';
export const OAUTH_BURNERS_READ = 'burners:read';
export const OAUTH_BURNERS_WRITE = 'burners:write';
export const OAUTH_CONTACTS_READ = 'contacts:read';
export const OAUTH_CONTACTS_WRITE = 'contacts:write';

/* External Links */
export const SUPPORT_ARTICLE_CANCEL_SUBSCRIPTION = 'https://support.burnerapp.com/article/205-how-do-i-cancel-my-subscription'

export function getHumanScopeText(scope, burnerNames) {
  switch (scope) {
    case OAUTH_MESSAGES_CONNECT:
      return {
        __html: `<b>Send</b> outbound and <b>view</b> incoming text messages with ${burnerNames.join(', ')}`,
      };
    case OAUTH_BURNERS_READ:
      return { __html: '<b>Read</b> information about your active Burners' };
    case OAUTH_BURNERS_WRITE:
      return { __html: '<b>Edit</b> settings for active Burners' };
    case OAUTH_CONTACTS_READ:
      return { __html: '<b>Read</b> information about your Burner contacts' };
    case OAUTH_CONTACTS_WRITE:
      return { __html: '<b>Edit</b> information about your Burner contacts' };
    default:
      return null;
  }
}

/*
 * Current SKUs for testing.
 * default is the current in-app pricing
 * offerset1w and offerset1m are free trials (1 week or 1 month). Full price for all plans.
 * offerset15 is 15% off yearly plans, offerset40 is 40% off yearly plans.
 */

export const subscriptionSkuWithPrice = {
  default: {
    oneLineMonthly: {
      name: 'Monthly',
      sku: 'com.adhoclabs.burner.subscription.month.1',
      price: '4.99',
      numLines: 1,
      isYearly: false,
    },
    oneLineYearly: {
      name: 'Yearly. 20% off',
      sku: 'com.adhoclabs.burner.subscription.year.1',
      price: '47.99',
      numLines: 1,
      isYearly: true,
    },
    threeLinesMonthly: {
      sku: 'com.adhoclabs.burner.subscription.concurrent.3.month.1',
      price: '14.99',
      numLines: 3,
      isYearly: false,
    },
    threeLinesYearly: {
      sku: 'com.adhoclabs.burner.subscription.concurrent.3.year.1',
      price: '139.99',
      numLines: 3,
      isYearly: true,
    },
  },
  offerset1w: {
    oneLineMonthly: {
      sku: 'com.adhoclabs.burner.subscription.month.1.trial.week.1',
      price: '4.99',
      numLines: 1,
      freeTrialLength: 7,
      isYearly: false,
    },
    oneLineYearly: {
      sku: 'com.adhoclabs.burner.subscription.year.1.trial.week.1',
      price: '47.99',
      numLines: 1,
      freeTrialLength: 7,
      isYearly: true,
    },
    threeLinesMonthly: {
      sku: 'com.adhoclabs.burner.subscription.concurrent.3.month.1.trial.week.1',
      price: '14.99',
      numLines: 3,
      freeTrialLength: 7,
      isYearly: false,
    },
    threeLineMonthlyVariantOne: {
      sku: 'com_adhoclabs_burner_subscription_concurrent_3_month_1_variant_1_trial_week_1',
      price: '9.99',
      numLines: 3,
      freeTrialLength: 7,
      isYearly: false,
    },
    threeLinesYearly: {
      sku: 'com.adhoclabs.burner.subscription.concurrent.3.year.1.trial.week.1',
      price: '139.99',
      numLines: 3,
      freeTrialLength: 7,
      isYearly: true,
    },
  },
  offerset1m: {
    oneLineMonthly: {
      sku: 'com.adhoclabs.burner.subscription.month.1.trial.month.1',
      price: '4.99',
      numLines: 1,
      freeTrialLength: 30,
      isYearly: false,
    },
    oneLineYearly: {
      sku: 'com.adhoclabs.burner.subscription.year.1.trial.month.1',
      price: '47.99',
      numLines: 1,
      freeTrialLength: 30,
      isYearly: true,
    },
    threeLinesMonthly: {
      sku: 'com.adhoclabs.burner.subscription.concurrent.3.month.1.trial.month.1',
      price: '14.99',
      numLines: 3,
      freeTrialLength: 30,
      isYearly: false,
    },
    threeLinesYearly: {
      sku: 'com.adhoclabs.burner.subscription.concurrent.3.year.1.trial.month.1',
      price: '139.99',
      numLines: 3,
      freeTrialLength: 30,
      isYearly: true,
    },
  },
  offerset15: {
    oneLineMonthly: {
      sku: 'com.adhoclabs.burner.subscription.month.1',
      price: '4.99',
      numLines: 1,
      isYearly: false,
    },
    oneLineYearly: {
      sku: 'com.adhoclabs.burner.subscription.year.1.variant.1',
      price: '50.99',
      numLines: 1,
      isYearly: true,
    },
    threeLinesMonthly: {
      sku: 'com.adhoclabs.burner.subscription.concurrent.3.month.1',
      price: '14.99',
      numLines: 3,
      isYearly: false,
    },
    threeLineMonthlyVariantOne: {
      sku: 'com_adhoclabs_burner_subscription_concurrent_3_month_1_variant_1_trial_week_1',
      price: '9.99',
      numLines: 3,
      freeTrialLength: 7,
      isYearly: false,
    },
    threeLinesYearly: {
      sku: 'com.adhoclabs.burner.subscription.concurrent.3.year.1.variant.1',
      price: '152.99',
      numLines: 3,
      isYearly: true,
    },
  },
  offerset40: {
    oneLineMonthly: {
      sku: 'com.adhoclabs.burner.subscription.month.1',
      price: '4.99',
      numLines: 1,
      isYearly: false,
    },
    oneLineYearly: {
      sku: 'com.adhoclabs.burner.subscription.year.1.variant.2',
      price: '35.99',
      numLines: 1,
      isYearly: true,
    },
    threeLinesMonthly: {
      sku: 'com.adhoclabs.burner.subscription.concurrent.3.month.1',
      price: '14.99',
      numLines: 3,
      isYearly: false,
    },
    threeLineMonthlyVariantOne: {
      sku: 'com_adhoclabs_burner_subscription_concurrent_3_month_1_variant_1_trial_week_1',
      price: '9.99',
      numLines: 3,
      freeTrialLength: 7,
      isYearly: false,
    },
    threeLinesYearly: {
      sku: 'com.adhoclabs.burner.subscription.concurrent.3.year.1.variant2',
      price: '107.99',
      numLines: 3,
      isYearly: true,
    },
  },
  mycorporation: {
    oneLineYearly: {
      sku: 'com.adhoclabs.burner.subscription.year.1.mycorporation1',
      price: '36.00',
      numLines: 1,
      isYearly: true,
    },
  },
};

/* Experiment Query Parameters */
export const experimentParams = ['offerset', 'exp'];
export const subscriptionType = {
  yearly: 'yearly',
  monthly: 'monthly',
}

// amplitude.analytic setup
export const amplitudeProperties = {
  defaultTracking: {
    attribution: true,
    pageViews: false,
    sessions: true,
    formInteractions: false,
    fileDownloads: false,
  },
}

export const signupFieldLabel = {
  firstName: 'first name',
  lastName: 'last name',
  email: 'email',
  phoneNumber: 'phone number',
  creditCard: 'card number',
  expMonth: 'expired month',
  expYear: 'expired year',
  cvc: 'cvc',
  zipCode: 'zip code',
}

export const ROUTER_PATH = {
  createAccountPhone: 'create-account/phone',
}

export const ReviewsData = [
  {
    text: 'The best way to add an additional phone number without the additional cost of a separate phone, period.',
    author: 'Mossy Q.',
  },
  {
    text: 'This is awesome, because sometimes life happens and I feel better with a good backup plan.',
    author: 'Mars W.',
  },
  {
    text: 'I like the solace I get from knowing I\'m not giving out my real number to strangers online.',
    author: 'Oz T.',
  },
  {
    text: 'Great for subscriptions and stuff where I don\'t want to give my real number.',
    author: 'L A.',
  },
  {
    text: 'I find Burner most useful when selling on Craigslist and giving prospective buyers my burner number.',
    author: 'Mark B.',
  },
  {
    text: 'Don\'t ever post your real number online. I found this out the hard way. Use this burner app, get yourself another number!',
    author: 'David G.',
  },
  {
    text: 'Best second line out there!! Love it!.',
    author: 'Dustin J.',
  },
  {
    text: 'This is the best phone app ever! I have three lines . . . you have voicemail for each line too!',
    author: 'Rachel J.',
  },
  {
    text: 'A must-have for Craigslist and local sales.',
    author: 'Kathryn D.',
  },
  {
    text: 'Easy to use, change numbers whenever you want. Keeps private calls private.',
    author: 'Jill L.',
  },
  {
    text: 'Much safer than giving out your personal number, which is linked to your home address online.\n',
    author: 'Lisa B.',
  },
]

export const DEVICE_TYPE = {
  ios: 'ios',
  android: 'android',
  default: 'unknown',
}

export const ROUTERS = {
  homePage: '/',
  dashboard: '/dashboard',
  areacode: '/areacode',
  createAccount: '/create-account',
  createAccountPhone: '/create-account/phone',
  checkout: '/checkout',
  numberCongrats: '/number-congrats',
  confirmation: '/confirmation',
  chooseNumber: '/choose-phone-number',
  socialProof: '/social-proof',
  premiumPage: '/premium-3',
  login: '/login',
  loginByPhone: '/login/phone',
  existAccount: '/app-account-exists',
  paywall: '/paywall',
};

export const NUMBER_FORMAT = {
  AU: '(##) ####-####',
  US: '(###) ###-####',
};

export const defaultCountryObject = {
  name: 'United States',
  dialCode: 1,
  countryCode: US,
}

export const defaultSubscribeData = {
  cta: 'Continue',
  title: 'Choose your plan',
  description: 'Get unlimited calls, texts and pics for your new number. \nPlus call spam blocking!',
  banner_above_cta: 'No commitment. Cancel anytime.',
  planOptions: [
    {
      plan_title: 'Monthly',
      plan_sku_id: 'com.adhoclabs.burner.subscription.month.1',
      plan_subtitle: 'Billed monthly at $4.99',
      plan_label: 'Most Popular',
      plan_price: '4.99',
    },
    {
      plan_title: 'Yearly. 20% off',
      plan_sku_id: 'com.adhoclabs.burner.subscription.year.1',
      plan_subtitle: '$3.99 per month. Billed yearly at $47.99',
      plan_price: '47.99',
    },
  ],
}
