import React from 'react';
import GooglePlayIcon from '../icons/paywall/googlePlay';
import BranchIcon from '../icons/paywall/branch';
import AppStoreIcon from '../icons/paywall/appStore';

import '!style-loader!css-loader!sass-loader!../../../styles/app-ratings.scss'
import ScoreIcon from '../icons/paywall/score';

function AppStoreRatings() {
  return (
    <section className="Main__container Main__container--secondary Max__container AppRatings__container grid-x align-center-middle">
      <div className="small-12 grid-x AppRatings__column align-center-middle">
        <div className="AppRatings__appStore grid-y small-5 medium-5 large-4 align-center-middle">
          <AppStoreIcon />
          <div className="AppRatings__cell u-relative u-width-full">
            <BranchIcon className="AppRatings__branch--left"/>
            <h2 className="AppRatings__branch--text AppRatings__branch--title">14 million +</h2>
            <h3 className="AppRatings__branch--text AppRatings__branch--subtitle">Downloads</h3>
            <BranchIcon className="AppRatings__branch--right"/>
          </div>
          <div className="AppRatings__score grid-y align-center-middle">
            <ScoreIcon />
            <span className="AppRatings__score--text">4.7 out of 5</span>
          </div>
        </div>
        <div className="AppRatings__googlePlay grid-y small-5 medium-5 large-4 align-center-middle">
          <GooglePlayIcon/>
          <div className="AppRatings__cell u-relative u-width-full">
            <BranchIcon className="AppRatings__branch--left"/>
            <h2 className="AppRatings__branch--text AppRatings__branch--title">5 million +</h2>
            <h3 className="AppRatings__branch--text AppRatings__branch--subtitle">Downloads</h3>
            <BranchIcon className="AppRatings__branch--right"/>
          </div>
          <div className="AppRatings__score grid-y align-center-middle">
            <ScoreIcon />
            <span className="AppRatings__score--text">4.4 out of 5</span>
          </div>
        </div>
      </div>
    </section>
  )
}

export default React.memo(AppStoreRatings);
