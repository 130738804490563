import * as OauthActionTypes from './oauth';
import * as VerifyActionTypes from './verify';
import * as ChooseNumberActionTypes from './chooseNumber';
import * as DashboardActionTypes from './dashboard';
import * as SubscribeActionTypes from './subscribe';
import * as BurnerDetailsActionTypes from './burnerDetails';
import * as SuccessModalActionTypes from './successModal';
import * as ApplicationsActionTypes from './applications';
import * as GeneralActionTypes from './general';
import * as InstanceTypes from './instance';
import * as Error from './error';
import * as StripeSubscribe from './stripe-subscribe';
import * as UserActionsTypes from './auth';

export default function reducer(state = {}, action) {
  switch (action.type) {
    case GeneralActionTypes.REQUESTING:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case Error.ERROR:
    case Error.VERIFY_CODE_ERROR:
    case DashboardActionTypes.RECEIVE_SUBSCRIPTIONS:
    case DashboardActionTypes.RECEIVE_PAYMENT_INFO:
    case VerifyActionTypes.USER_VERIFIED:
    case VerifyActionTypes.RECEIVE_CODE:
    case VerifyActionTypes.LOGOUT_SUCCESS:
    case ChooseNumberActionTypes.RECEIVE_PHONE_NUMBERS:
    case ChooseNumberActionTypes.CREATED_BURNER:
    case ChooseNumberActionTypes.ERROR_NUMBER:
    case BurnerDetailsActionTypes.RECEIVE_BURNER_DETAILS:
    case BurnerDetailsActionTypes.DEV_CONNECT_SUCCESS:
    case BurnerDetailsActionTypes.DEV_UNLINK_SUCCESS:
    case SuccessModalActionTypes.RECEIVE_OUTBOUND_WEBHOOK_TEST:
    case SuccessModalActionTypes.SUBSCRIPTION_CANCELLED:
    case DashboardActionTypes.PAYMENT_UPDATED:
    case BurnerDetailsActionTypes.RECEIVE_CONNECTION_SETTINGS:
    case BurnerDetailsActionTypes.NO_CONNECTION_ACTION:
    case OauthActionTypes.RECEIVE_CLIENT_INFO:
    case OauthActionTypes.RECEIVE_HUMAN_FRIENDLY_SCOPES:
    case OauthActionTypes.AUTHORIZE_SUCCESS:
    case ApplicationsActionTypes.RECEIVE_AUTHORIZATIONS:
    case ApplicationsActionTypes.REVOKE_AUTHORIZATION_SUCCESS:
    case SubscribeActionTypes.NUMBER_IS_TAKEN:
    case SubscribeActionTypes.NUMBER_ASSOCIATED:
    case SubscribeActionTypes.CHECK_USER_SUBSCRIPTION:
    case SubscribeActionTypes.SET_PAYMENT_ERROR:
    case StripeSubscribe.STRIPE_CLIENT_SECRET:
    case InstanceTypes.RECEIVE_INSTANCE_BURNER_DETAILS:
    case UserActionsTypes.RECEIVE_USER_DATA:
    case SubscribeActionTypes.SUBSCRIBE_LIST:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
}
