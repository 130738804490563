import {
  GA_KEY,
  PURCHASED,
  AMPLITUDE_WEB_SUBSCRIPTION_PURCHASE_SUCCESS,
  AMPLITUDE_PROPERTY_DEVICE,
  AMPLITUDE_WEB_FAILURE,
  AMPLITUDE_AREA_CODE_VIEW,
  AMPLITUDE_SIGNUP_SUCCESS,
  CUSTOM_WEB_EVENT,
  NUX_NUMBER_VERIFICATION_WEB,
  RESELECT_AREA_CODE,
  START_FLOW_SQUEEZE_WEB, SUBMIT_PAYMENT_INFO, AMPLITUDE_LOGIN_SUCCESS,
} from '../constants.js';
import ReactGA4 from 'react-ga4';
import * as amplitude from '@amplitude/analytics-browser';
import {getCurrentUser} from './user';

const SEPERATOR = '::';

ReactGA4.initialize(GA_KEY);

function getQueryParams() {
  const regex = /[?&]([^=#]+)=([^&#]*)/g;
  const params = {};
  let match;
  while ((match = regex.exec(window.location.href))) {
    params[match[1]] = match[2];
  }
  return params;
}

function str(val) {
  return val ? val : '';
}

export function getReferrerToken() {
  const params = getQueryParams();
  let token = '';
  token +=
    str(params.utm_source) +
    SEPERATOR +
    str(params.utm_campaign) +
    SEPERATOR +
    str(params.utm_medium) +
    SEPERATOR +
    str(params.utm_content);
  token = token.replace(/:+$/, '');
  return token ? token : 'Organic';
}

export function trackSitebuilderSendSms() {
  ReactGA4.event({
    category: 'User',
    action: 'Clicked Sitebuilder Send A Text',
  });
}

function getAmplitudeDeviceType() {
  const deviceType = {
    phone: 'phone',
    tablet: 'tablet',
    desktop: 'desktop',
  }
  const windowSize = window !== undefined && window.screen.width;

  if (!windowSize) return 'unknown';

  if (windowSize < 768) {
    return deviceType.phone;
  }

  if (windowSize >= 768 && windowSize < 1024) {
    return deviceType.tablet;
  }

  return deviceType.desktop;
}

export const amplitudeDeviceProperty = {
  [AMPLITUDE_PROPERTY_DEVICE]: getAmplitudeDeviceType(),
}

export function trackAmplitudeFailedEvent(reason, source = 'server') {
  return amplitude.track(AMPLITUDE_WEB_FAILURE, {
    reason,
    source,
    ...amplitudeDeviceProperty,
  });
}

export function logPurchase(subId, sku, price, subscriptionName) {
  const item_category = sku.includes('trial') ? 'free_trial' : 'direct_purchase';
  const payment = !sku.includes('trial');

  amplitude.track(AMPLITUDE_WEB_SUBSCRIPTION_PURCHASE_SUCCESS, {
    sku,
    payment,
    ...amplitudeDeviceProperty,
  });

  dataLayer.push({
    event: CUSTOM_WEB_EVENT,
    customWebEventName: SUBMIT_PAYMENT_INFO,
  });

  dataLayer.push({ ecommerce: null });
  dataLayer.push({
    event: PURCHASED,
    value: price,
    currency: 'USD',
    transaction_id: subId,
    items: [
      {
        item_id: sku,
        item_name: subscriptionName,
        item_category,
        price,
        quantity: 1,
      },
    ],
  });
}

export function verifyNumberSignUpEvents() {
  amplitude.track(AMPLITUDE_SIGNUP_SUCCESS, {
    provider: 'phone',
    method: 'phone',
    ...amplitudeDeviceProperty,
  });

  dataLayer.push({
    event: CUSTOM_WEB_EVENT,
    customWebEventName: NUX_NUMBER_VERIFICATION_WEB,
  });
}

export function signupAnalyticEvents() {
  amplitude.track(AMPLITUDE_AREA_CODE_VIEW, amplitudeDeviceProperty);
}

export function reselectAreaEvents() {
  dataLayer.push({
      event: CUSTOM_WEB_EVENT,
      customWebEventName: RESELECT_AREA_CODE,
    },
    {
      event: CUSTOM_WEB_EVENT,
      customWebEventName: START_FLOW_SQUEEZE_WEB,
    }
  );
}

export function existAccountEvent() {
  amplitude.track(AMPLITUDE_SIGNUP_SUCCESS, {
    provider: 'phone',
    method: 'phone',
    ...amplitudeDeviceProperty,
  });

  trackAmplitudeFailedEvent('already subscribed')
}

// use for cases when user use onboarding steps for login to the app
export function successOnboardingLoginEvent() {
  amplitude.track(AMPLITUDE_LOGIN_SUCCESS, {
    provider: 'phone',
    method: 'phone',
    ...amplitudeDeviceProperty,
  })
  amplitude.setUserId(getCurrentUser()?.id);
}
